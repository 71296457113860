import React from 'react';
import './TrinetResults.css';
import TrinetResults from './TrinetResults'
import {api} from './trinetApi'

const nConsumers = 5

export default class TrinetProjectResults extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inQuery:false
        }
        this.consumers = new Array(nConsumers).fill(false)
    }

    componentDidMount() { 
        api.registerAsView('inQuery',this)
        api.registerAsView('results',this)
        api.registerAsView('projectResults',this)
    }

    componentWillUnmount() {
        api.unregisterAsView('inQuery',this)
        api.unregisterAsView('results',this)
        api.unregisterAsView('projectResults',this)
    }

    updateInQuery(val) {
        this.setState({inQuery:val})
    }

    updateResults(res) {
        if ( this.consumers[0] ) this.consumers[0].updateResults({...res,expandable:true})
        for ( let i = 1 ; i < nConsumers ; i++ ) {
            if ( this.consumers[i] ) this.consumers[i].updateResults({
                results:[],
                project:false,
                lang:false
            })
        }
    }

    updateProjectResults(res) {
        res.project_results = res.project_results.sort( (a,b) => b[2]-a[2] )
        for ( let i = 2 ; i < res.project_results.length ; i++ ) {
            if ( res.project_results[i][0] == 'mastodonlinks' ) {
                // Switch mastodonlinks to the second place if found below second place
                const tmp = res.project_results[1]
                res.project_results[1] = res.project_results[i]
                res.project_results[i] = tmp
            }
        }
        for ( let i = 0 ; i < nConsumers ; i++ ) {
            if ( this.consumers[i] ) {
                if ( i < res.project_results.length ) {
                    this.consumers[i].updateResults({
                        results:res.project_results[i][1],
                        project:res.project_results[i][0],
                        lang:res.lang,
                        expandable:false
                    })
                } else {
                    this.consumers[i].updateResults({
                        results:[],
                        project:false,
                        lang:false,
                        expandable:false      
                    })
                }
            }
        }
    }   

    registerConsumer( consumer, index, unregister = false ) {
        if ( !unregister ) {
            this.consumers[index] = consumer
        } else {
            if ( this.consumers[index] == consumer ) this.consumers[index] = false
        }
    }

    render() {

        return <>
            { this.consumers.map( (_, index) => 
                <TrinetResults inQuery={this.state.inQuery} key={index} provider={this.registerConsumer.bind(this)} index={index}/>
            )}
        </>
    }
} 


