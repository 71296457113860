import React from 'react';
import ControlledInput from './controlledInput';
import Select from 'react-select'
import button_img from './img/search-2-line.png'
import ProjectPicker from './ProjectPicker';
import MetawordPicker from './MetawordPicker';
import TrinetResults from './TrinetResults';

import {api} from './trinetApi'

class TrinetForm extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        query:api.query,
        lang:api.lang,
        project:api.projectName,
        projects:[],
        sortBy:TrinetForm.optionsOrderBy[1],
        metaWords:[], // The list of metaword types that exist in current project e.g. ['instance','author']
        known:{}, // type -> list of values
        selected:[], // An array of [type,value] metawords 'type:value'
        resultCount:0,
        allCount:0,
        inQuery:false,
        invalidateQuery:false // true : need to perform a query with new states
      }
      this.inQuery = false
      this.t = api.common.t
    }

    componentDidMount() { 
        api.registerAsView('projects',this)
        api.registerAsView('meta',this)
        api.registerAsView('projectName',this)
        api.registerAsView('metawordCounts',this)
        api.registerAsView('resultCount',this)
        api.registerAsView('query',this)
        this.updateProjects(api.projects)
    }

    componentWillUnmount() {
        api.unregisterAsView('projects',this)
        api.unregisterAsView('meta',this)
        api.unregisterAsView('projectName',this)
        api.unregisterAsView('metawordCounts',this)
        api.unregisterAsView('resultCount',this)
        api.unregisterAsView('query',this)
    }

    componentDidUpdate() {
        if ( this.state.invalidateQuery && !this.inQuery ) {
            this.setState({invalidateQuery:false})
            this.onQuery()
        }
    }

    /** */
    splitMetaword( word, prefixes ) {
        for ( const prefix of prefixes ) {
            if ( word.startsWith(prefix+':') ) {
                return [prefix,word.substring(prefix.length+1)]
            }
        }
        return ['',word]
    }

    /**
     * @param {*} counts Object where key is a metaword as String (:-separated string) and value is its number of occurences
     */
    updateMetawordCounts(counts) {
        const prefixes = api.getMetaWords()
        let known = this.state.known
        for ( let word in counts ) {
            const count = counts[word]
            word = this.splitMetaword(word,prefixes)
            let current = known.hasOwnProperty(word[0]) ? known[word[0]] : []
            if ( ! current.some( f => f[0] == word[1] ) ) {
                current.push([word[1],count])
                known[word[0]] = current
            }
        }
        this.setKnown(known)
    }

    updateResultCount(count,lang) {
        this.setState({resultCount:count,resultLang:lang,known:{}})
    }

    updateAllCount(count) {
        this.setState({allCount:count})
    }

    updateQuery( query, metawords ) {
        if ( !this.inQuery ) {
            this.setState({query:query, selected:metawords, invalidateQuery:true })
        }
    }

    setKnown( known ) {
        for ( const metaword in known ) {
            known[metaword].sort(
                (a,b) => {
                    if (( !Array.isArray(a) )&& Array.isArray(b)) return -1;
                    if (Array.isArray(a) && !Array.isArray(b)) return +1;
                    if (( !Array.isArray(a) )&& !Array.isArray(b)) return a>b ? +1 : -1
                    return (a[1]>b[1])?-1:+1
                }
            )
        }
        this.setState({ known:known })
    }

    updateProjects(projects) {
        if ( projects.length && !this.state.projects.length ) {
            const {query,metawords,projects} = api
            this.setState({
                projects:projects,
                metaWords:api.getMetaWords(),
                query:query,
                selected:metawords
            })
            if ( query || metawords ) this.setState({invalidateQuery:true}) // this.onQuery({ query:query, selected:metawords, projects:projects })
        }
    }

    useTimestamps(options) {

        options = options || {}
        const projects = options.projects || this.state.projects
        const project = options.project || this.state.project

        return projects.some( p => ( p.name == project )&& p.useTimestamps  )
    }

    setLang(lang) {
        api.setLang(lang.value)
    }

    knownMeta(words) {
        this.setState( prevState => {
            let known = prevState.known
            words.forEach(
                word => {
                    const iColon = word.indexOf(':')
                    if ( iColon>0 ) {
                        const metaword = word.substring(0,iColon)
                        const value = word.substring(iColon+1)
                        if ( known.hasOwnProperty(metaword) ) {
                            if ( ! known[metaword].some( (f,i,a) => ( f[0] == value ) ? ( ++a[i][1], true ) : false  ) ) {
                                known[metaword].push([value,1])
                            }
                        } else {
                            known[metaword] = [ [value,1] ]
                        }
                    }
                }
            )

/*            for ( const metaword in known ) {
                known[metaword].sort(
                    (a,b) => {
                        return (a[1]>b[1])?-1:+1
                    }
                )
            }    
  */          
            return {known:known}
        })
    }

    /**
     * 
     * @param {*} meta an object with content {type:value} for adding metaword 'type:value'
     */
    addMeta(meta) {
        this.setState( prevState => {
            let selected = prevState.selected
            for ( const type in meta ) {
                const element = [ type, meta[type] ]
                if ( !selected.some( s => s[0] == type && s[1] == meta[type] ) ) selected.push( element )
                else selected = selected.filter( s => ( s[0] != type )||( s[1] != meta[type] ) )
            }
            return {
                selected:selected,
                invalidateQuery:true
            }
        })
        // this.onQuery({selected:selected})
    }

    updateProjectName(projectName,lang) {
        this.setState({
            project:projectName,
            lang:lang,
            metaWords:api.getMetaWords(),
            selected:[],
            invalidateQuery:true
        })
        /* this.onQuery({
            selected:selected
        })*/
    }
      
    onQuery( options={} ) {

        this.inQuery = true
        api.setInQuery(true)
        const query = options.query || this.state.query
        const selected = options.selected || this.state.selected

        let payload = {
            action:'query',
            lang:options.lang || this.state.lang,
            project:this.state.project,
            sortBy:this.useTimestamps(options)?this.state.sortBy.value:'false',
            metaWords:JSON.stringify(selected),
            limit:this.props.limit
        }

        if ( !this.state.project ) {
            payload.action = 'queryall'
            payload.query = query || ''
        } else {
            const match = query.match(/^like\s*:\s*(.*)$/)
            if ( match ) {
                payload.url = match[1]
            } else {
                payload.query = query || ''
            }
        }

        api.notifyChanges( { query:query, metawords: selected } )

        api.post(
            payload,
            ()=>{ this.inQuery = false; api.setInQuery(false) },
            ()=>{ this.inQuery = false; api.setInQuery(false) }
        )
    }

    renderLangPicker() {
        let currentProjectName = this.state.project
        let currentProject = this.state.projects.find( project => project.name == currentProjectName )
        return currentProject ? <div className="input-select"><Select placeholder="Langue"
            classNames={TrinetForm.selectClassNamesNoBorder}
            styles={TrinetForm.selectStyles}
            options={currentProject.langs.map(
                lang => { return { value:lang, label:TrinetForm.langValueToLabel(lang) } }
            )}
            value={ this.state.lang ? {value:this.state.lang,label:TrinetForm.langValueToLabel(this.state.lang)} : false }
            onChange={this.setLang.bind(this)}
            />
        </div> : ''
    }

    capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    

    getKnown( metaword ) {
        return this.state.known.hasOwnProperty(metaword) ? this.state.known[metaword] : []
    }

    /**
     * 
     * @param {*} metaword A metaword type e.g. instance, author ...
     * @returns An option list for Select component, with all selected metawords for given type
     */
    getSelectedOptions( metaword ) {
        let options = []
        this.state.selected.forEach( selected => {
            const [type,value] = selected
            if ( type == metaword ) {
                options.push( {value:value,label:value} )
            }
        })
        return options
    }

    renderMetawordsPicker() {
        /*
        const optionColor = (isFocus,isSelected) => isSelected ? '#fff': ( isFocus ? '#6364ff' : '#777' ) 
        const CustomOption = (props) => {
            const { innerProps, innerRef } = props;
            return <div className="menu-list-option" ref={innerRef} {...innerProps} style={{color:optionColor(props.isFocused,props.isSelected)}}>
                {props.label} {props.data.count ? <span>{props.data.count}</span> : ''}
            </div>
        }
        const components = {Option: CustomOption}
        const noQuery = ! this.state.query

        return <React.Fragment>
            {this.state.metaWords.map( metaword => {
                let displayWord = this.capitalizeFirstLetter( metaword )
                return <div className="input-field" key={metaword}>
                    <div className="input-select">
                    <CreatableSelect placeholder={displayWord} isClearable isMulti 
                        onChange={this.setMetaWord.bind(this,metaword)} 
                        classNames={TrinetForm.selectClassNames}
                        components={components}
                        options={
                            this.getKnown(metaword).map(
                                value => {
                                    return Array.isArray(value) ? { value:value[0], label:value[0], count:noQuery?value[1]:false} :
                                        { value:value, label:value, count:false }
                                }
                            )
                        }
                        styles={TrinetForm.selectStyles}
                        value={ this.getSelectedOptions(metaword) }
                    />
                </div>
                </div>
            })}

        </React.Fragment>
        */
    }

    setSortBy(what) {
        this.setState({sortBy:what})
    }

    renderSortControls() {
        return this.useTimestamps() ? <div className="input-select">
            <Select placeholder="Trier par ..." 
                options={TrinetForm.optionsOrderBy} 
                value={this.state.sortBy} 
                classNames={TrinetForm.selectClassNames}
                styles={TrinetForm.selectStyles}
                onChange={this.setSortBy.bind(this)}/>
        </div> : ''
    }

    renderExternalLinkCheckbox() {
        const isSet = ( this.state.project === 'mastodonlinks' )
        return (( this.state.project === 'mastodon' )||( this.state.project === 'mastodonlinks' ))
        ? <><label><input 
            type="checkbox" 
            checked={isSet} 
            onChange={ e => this.setProject( {value: e.target.checked ? 'mastodonlinks' : 'mastodon' } )}
        />External links</label></>
        :''
    }

    setProject(project) {
        api.setProjectName(project)
    }

    /**
     * @param {*} type A metaword type e.g. 'instance', 'author'
     * @param {*} value An array of {label,value} where value is the metaword value.
     */
    setMetaWord( type, value ) {
        // remove old metawords of type type
        let selected = this.state.selected.filter( metaword => metaword[0] != type )
        // insert new metawords of type type given in parameter value
        value.forEach( v=> {
            selected.push([type,v.value])
        })
        this.setState({selected:selected})
    }

    setLang( option ) {
        api.setLang( option.value )
    }

    onEnter(e) {
        this.onQuery()
        e.target.blur()
        api.scrollToResults()
    }

    render() {
        if ( ! this.state.projects ) {
            return <div className="trinet_form_loading"></div>
        }
        return <>
            {this.state.project ?
                   <div className="subtitle_container"> <div className="subtitle" onClick={api.setProjectName.bind(api,false)}>✕ {TrinetResults.projectDisplay(this.state.project)}</div></div> : ''}

            {this.state.lang ? <div className="search_input_container">
                    <ControlledInput
                        className="search_input"
                        withSaveButton={button_img}
                        placeholder="Mots clefs"
                        type='text' property='query' container={this} onSubmit={this.onEnter.bind(this)}/>
                    <div className="icon_wrap" onClick={this.onEnter.bind(this)}>
                        <svg version="1.1" width="20" height="20" viewBox="0 0 20 20">
                            <path d="M18.869 19.162l-5.943-6.484c1.339-1.401 2.075-3.233 2.075-5.178 0-2.003-0.78-3.887-2.197-5.303s-3.3-2.197-5.303-2.197-3.887 0.78-5.303 2.197-2.197 3.3-2.197 5.303 0.78 3.887 2.197 5.303 3.3 2.197 5.303 2.197c1.726 0 3.362-0.579 4.688-1.645l5.943 6.483c0.099 0.108 0.233 0.162 0.369 0.162 0.121 0 0.242-0.043 0.338-0.131 0.204-0.187 0.217-0.503 0.031-0.706zM1 7.5c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5-6.5-2.916-6.5-6.5z"></path>
                        </svg>
                    </div>
                </div> : '' }


            <div className="row">

                {this.state.project ? '' : <ProjectPicker projects={this.state.projects}/>}


                {this.state.project ? <>
                    <MetawordPicker known={this.state.known} selected={this.state.selected} addMeta={this.addMeta.bind(this)}/>
                    </> : ''}


                {this.useTimestamps() ? this.renderSortControls() : ''}

            </div>
        </>
    }
  }

TrinetForm.langOptions = [
    {value:'en',label:'English'},
    {value:'fr',label:'Français'},
    {value:'ar',label:'Arabe'},
    {value:'da',label:'Danois'},
    {value:'nl',label:'Néerlandais'},
    {value:'fi',label:'Finlandais'},
    {value:'de',label:'Allemand'},
    {value:'hu',label:'Hongois'},
    {value:'it',label:'Italien'},
    {value:'no',label:'Norvégien'},
    {value:'pt',label:'Portugais'},
    {value:'ro',label:'Roumain'},
    {value:'ru',label:'Russe'},
    {value:'es',label:'Espagnol'},
    {value:'sv',label:'Suédois'}
]

TrinetForm.langValueToLabel = value => TrinetForm.langOptions.find( option => option.value == value )?.label


TrinetForm.optionsOrderBy = [
    {value:'false',label:'Relevance'},
    {value:'stamp:-1',label:'Most recent first'}
]

TrinetForm.selectClassNames = {
    'control': (_state) => 'choices__inner',
    'container': (_state) => 'choices',
    'menu': (_state) => 'choices__list',
    'menu-list': (_state) => 'menu-list',
    'option': (_state) => 'menu-list-option',
    'indicatorSeparator': (_state)=>'indicatorSeparator',
    'multiValue':(_state)=>'multiValue',
    'singleValue':(_state)=>'singleValue'

}

TrinetForm.selectClassNamesNoBorder = { 
    ...TrinetForm.selectClassNames,
    'control': (_state) => 'choices__inner__noborder'
}

TrinetForm.selectStyles = { 
    option : (base,state) => {
        let color = '#777'
        if ( state.isFocused ) color = '#6364ff'
        if ( state.isSelected ) color = '#fff'
        return { ...base, color: color, backgroundColor:'#000' }
    }
}


export default TrinetForm;
  
