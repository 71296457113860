import React from 'react';
import TrinetForm from './TrinetForm'
import TrinetProjectResults from './TrinetProjectResults'
import LoginForm from './LoginForm';
import burger_img from './img/burger.png'
import {api} from './trinetApi'

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loginCollapsed:true
    }
  }

  componentDidMount() { 
    if ( api.firstSubscribe ) {
        this.setState({loginCollapsed:false})
    }
    if ( api.firstLogin ) {
        this.setState({loginCollapsed:false})
    }
  }

  onBurger() {
    this.setState( (prevState) => ({loginCollapsed:!prevState.loginCollapsed}) ) 
  }

  onLoginCollapse() {
    this.setState({loginCollapsed:true})
  }

  updateProjectName(projectName,lang) {
    this.setState({projectName:projectName})
  }

  render() {
    return <>
          <div className="head">
            <img className="burger" src={burger_img} onClick={this.onBurger.bind(this)}/>
            <div className="title" onClick={api.setProjectName.bind(api,false)}>Wikiwix</div>
            <LoginForm collapsed={this.state.loginCollapsed} onCollapse={this.onLoginCollapse.bind(this)}/>
            <TrinetForm limit={1000}/>
          </div>
          <TrinetProjectResults />
    </>
  }
}

export default App;


