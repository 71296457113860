import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import {api} from './trinetApi'
import TrinetResults from './TrinetResults';


function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
  
    return <div className={isFirstItemVisible?'arrow disabled':'arrow'} onClick={()=>scrollPrev()}>◀</div>
  }
  
function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return <div className={isLastItemVisible?'arrow disabled':'arrow'} onClick={()=>scrollNext()}>▶</div>
}
  
export default function(p) {

    return (
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {p.projects.map( project => <div key={project.name} className="selector" onClick={api.setProjectName.bind(api, project.name)}>
              {TrinetResults.projectDisplay(project.name)}
          </div>
          )}
        </ScrollMenu>
      );
}

    /*
    return <div className="selector-row">
    {p.projects.map(
        project => <div 
            key={project.name}
            className="selector" 
            onClick={ api.setProjectName.bind(api,project.name) }
        >{project.name}</div>
    )}
    <div className="selector_right" onClick={}>▶</div>
    </div>
    */
