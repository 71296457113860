import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import './PaypalForm.css'
import img_checkbox from './img/blue-checkbox.png'
import {api} from './trinetApi'

export default class PaypalForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            plan:0
        }
        this.t = api.common.t
    }

    renderPlanBox(plan,desc) {
        return <label 
            className={(this.state.plan==plan)?'selected':''}
            onClick={()=>{this.setState({plan:plan})}}
        >
            <span className="imagebox"><img src={img_checkbox}/></span>
            {desc}
        </label>
    }

    createSubscription(data,actions) {
        return api.common.createSubscription(this.state.plan)
    }

    render() {
        return <PayPalScriptProvider options={{ 
            clientId: "test",
            components: "buttons",
            intent: "subscription",
            vault: true
        }}>
            <div className="paypal_row">
                <div className="paypal_box paypal_text">
                    <p>{this.t('without_unlimited')}</p>
                    <p>{this.t('subscribe_to',api.price)}</p>
                    <h2>{this.t('Pick a plan')}</h2>
                    <p>
                        {this.renderPlanBox(0,this.t('1€ per month'))}
                        {this.renderPlanBox(1,this.t('10€ per year'))}
                        {this.renderPlanBox(2,this.t('5€ per year (student offer)'))}
                    </p>

                </div>
                <div className="paypal_box paypal_buttons"> 
                    <PayPalButtons
                        createSubscription={this.createSubscription.bind(this)}
                        onApprove={api.common.onApprove.bind(api.common)}
                    />
                </div>
            </div>
        </PayPalScriptProvider>
    }
}