
import imgConnex from './file-search-line.png'
import LinkBox from './LinkBox';
import {api,t} from '../trinetApi'
import ResultBox from './ResultBox'
import MetaButton from './MetaButton';
import MetaBadge from './MetaBadge'
import { useState } from 'react';


function stripTags(textToEscape) {
    return textToEscape.replace(/<[^>]+>/g, '');
}

const projectsInArchive = ['orange','skyblogs','archives']

const StripText = ({text})=>{
    const [folded,setFolded] = useState(true)
    if ( !text ) return ''
    const stripped = stripTags( text )
    return ( stripped.length < 512 )? <span dangerouslySetInnerHTML={{__html:stripped}}/> : <div 
        className={folded?'folded':'unfolded'}
        onClick={()=>{ setFolded(!folded) } }>
        {folded?<>{<span dangerouslySetInnerHTML={{__html:stripped.substring(0,512)}}/>} ... <a className="readmore"> {t('Read more')}</a></>
        : <span dangerouslySetInnerHTML={{__html:stripped}}/> }
    </div>
}

export default function ResultDefault(props) {

    const {meta,project,expandable} = props
    const originalUrl = props.url

    if ( !originalUrl ) return <ResultBox {...props}></ResultBox>

    const url = originalUrl.replace(/^https?:\/\/web\.archive\.org\/web\/[0-9]+\//,'')
    const pregs = url.match( /^https?:\/\/([^\/]+)/ )
    const hostname = pregs ? pregs[1] : ''

    const targetUrl = ( projectsInArchive.includes(project) )? api.cacheUrl( url ) : url

    const title = meta.title ? <a href={targetUrl} className="title" target="_blank" dangerouslySetInnerHTML={{__html:stripTags(meta.title)}}></a> : <></>
    const datec = ( meta.created_at && typeof meta.created_at == 'number' )? new Date(meta.created_at*1000) : 0
    const author = <MetaButton {...props} name="author" value={meta.author}></MetaButton>
    
    const discipline = <MetaBadge value={meta.discipline}/>
    const created_at = datec
    ? <a className="badge">{datec.toDateString()}</a>
    : ''
    
    const type = <MetaButton {...props} name="type" value={meta.type}/>

    let body = <StripText text={meta.body}/>
    if ( meta.abstract && ! meta.abstract.match(/^\s*Lien externe\s*$/) ) body = <StripText text={meta.abstract}/>

    const wikis = meta.wikis ? <p className="resume wikis">
        {meta.wikis.map(wiki=>{
            const pm = wiki.match(/^([-a-z]+\.[a-z]+\.org)\/(.+)$/)
            return <span key={wiki}>▸ <a target="_blank" href={'https://'+pm[1]+'/wiki/'+pm[2]}>{pm[2]}</a></span>
        })}
    </p>:''


    return <ResultBox {...props}>

        <div className="header">
            <div className="author" onClick={ /*api.addMeta.bind(api,{'domain':hostname}) */ ()=>{} }>
                <a href={targetUrl} target="_blank">{hostname}</a>
            </div>
            <div className="tools"> {type} {expandable?<img src={imgConnex} title="Search similar documents" onClick={api.searchConnex.bind(api,originalUrl)}/>:''}</div>
        </div>
        <div className="content">
            <div className="rightbox">
            <div className="footer">{author} {discipline} {created_at}</div>
            <div className="resume">{title}</div>
            <div className="resume">{body}</div>
            {wikis}
            </div>
        </div>
        {targetUrl ? <LinkBox url={url} link={targetUrl} api={api} showDomain={false}/> : <></> }

    </ResultBox>
}