import React from 'react';
import {api} from '../trinetApi'

const excludeMiniature = [
   'www.theses.fr' 
]

function prepareHtml(html) {
    return <span dangerouslySetInnerHTML={{__html:html.replace(/<[^>]+>/g, '')}}/>
}

export default class LinkBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            image:false,
            title:false
        }
    }

    componentDidMount() { 
        api.post({
            action:'get_url_meta',
            url:this.props.url
        },(result)=>{
            const image = result.url_meta.image
            const pregs = image ? image.match( /^https?:\/\/([^\/]+)/ ) : false
            const imageHostname = pregs ? pregs[1] : ''
            if ( excludeMiniature.includes(imageHostname) ) result.url_meta.image = false
            this.setState({ title:result.url_meta.title,image:result.url_meta.image })
        })

    }

    showDomain() {
        return this.props.hasOwnProperty('showDomain') ? this.props.showDomain : true
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    

    render() {

        const domainMatch = this.props.url.match( /^https?:\/\/(?:www\.)?([^\/]+)\.[a-z]+\// )
        const domain = domainMatch? <div className="urldomain">{this.capitalizeFirstLetter(domainMatch[1].replace(/[\._0-9-]+/g,' '))}</div> : false

        return this.state.title ? <a className="content urlcontent" href={this.props.link} target="_blank">
                { this.state.image ? <div className="leftbox">
                    <img src={ api.cacheDisplayUrl(this.state.image) }/>
                </div> : <></> }
                <div className="rightbox urlbox">
                    {prepareHtml(this.state.title)}
                    {domain}
                </div>
            </a> : <></>
    }

}