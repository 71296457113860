import React from 'react';
import './TrinetResults.css';
import {api,t} from './trinetApi'

const flatProjects = ['theses','wikipedia']


class TrinetResults extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            results:[],
            project:false,
            lang:false,
            groupDomains:true,
            domains:{},
            metas:[],
            unfoldedDomains:[],
            loading:false,
            expandable:false
        }
        this.loading = false
        this.bottomRef = React.createRef()
        const intersecter = this.onIntersect.bind(this)
        this.observer = new IntersectionObserver(function (entries, observer) {
            entries.forEach((entry) => {
                if ( entry.isIntersecting ) {
                    intersecter()
            }})
        });
        this.alreadyObeserving = false
    }

    componentDidMount() { 
        this.props.provider( this, this.props.index )
    }

    componentDidUpdate() {
        if ( ( ! this.alreadyObeserving ) && this.bottomRef.current ) {
            this.observer.observe( this.bottomRef.current );
            this.alreadyObeserving = true
        }
    }

    componentWillUnmount() {
        this.observer.disconnect()
        this.props.provider( this, this.props.index, true )
    }

    onIntersect() {
        if ( this.state.expandable && !this.loading ) this.loadNextMeta()
    }

    onForceExpand() {
        if ( !this.loading ) this.loadNextMeta()
    }

    loadNextMeta( c = 10 ) {
        this.loading = true
        this.setState({loading:true})
        const n = this.state.metas.length
        if ( n < this.state.results.length ) {
            api.post({
                action:'gets',
                project:this.state.project,
                lang:this.state.lang,
                ids:this.state.results.slice(n,n+c)
            }, this.receive.bind(this,n))
        }
    }

    gotAllMeta() {
        return this.state.metas.length >= this.state.results.length
    }

    receive(n,res) {
        this.setState( ( prevState, props )=> {
            let metas = prevState.metas
            let domains = prevState.domains
            res.forEach( (meta,i) => {
                const index = n+i
                if ( meta ) {
                    const url = meta.url.replace(/^https?:\/\/web\.archive\.org\/web\/[0-9]+\//,'')
                    const mdomain = url.match(/^https?:\/\/([^\/]+)/)
                    const domain = mdomain ? mdomain[1] : false
            
                    if ( meta.words ) api.knownMeta(meta.words)
                    
                    if ( domain ) {
                        if ( !( domain in domains )) {
                            domains[domain] = []
                        }
                        if ( ! domains[domain].includes(index) ) {
                            domains[domain].push( index )
                            domains[domain].sort()
                        }
                    }

                    metas[index] = {url:url,meta:meta.meta,domain:domain}
                } else metas[index] = false
            })
            setTimeout( () => { this.loading = false }, 1000 )
            return {metas:metas,domains:domains,loading:false}
        })
    }

    updateResults(res) {
        this.setState({
            metas:[],
            results:res.results,
            project:res.project,
            groupDomains: ! flatProjects.includes(res.project),
            lang:res.lang,
            domains:{},
            expandable:res.expandable
        }, this.loadNextMeta.bind(this))
    }

    getDomainGroups() {
        let domainGroups = []
        for ( const [domain,group] of Object.entries( this.state.domains ) ) {
            if ( false && this.state.unfoldedDomains.includes(domain) ) {
                // Split group into singletons, because domain is unfolded
                for ( const index of group ) {
                    domainGroups.push([index])
                }
            } else {
                domainGroups.push(group)
            }
        }
        return domainGroups.sort( (a,b)=>a[0]-b[0] )
    }

    collapse( domain ) {
        this.setState( prevState => { 
            let {unfoldedDomains} = prevState
            if ( unfoldedDomains.includes(domain) ) {
                unfoldedDomains = unfoldedDomains.filter( d => d!=domain )
            } else {
                unfoldedDomains.push(domain)
            }
            return { unfoldedDomains:unfoldedDomains }
        } )
    }

    renderGroups() {
        const { project, lang, results, metas, expandable } = this.state
        const domainGroups = this.getDomainGroups()// Object.values( domains ).sort( (a,b)=> a[0]-b[0] )
        const renderer = api.getRenderer( project )

        let rt = []
        for ( const ids of domainGroups ) {
            const result = results[ids[0]]
            if ( metas[ids[0]] ) {
                const domain = metas[ids[0]].domain
                const unfolded = this.state.unfoldedDomains.includes(domain)
                rt.push( React.createElement( renderer,{
                    key:project+'/'+lang+'/'+result,
                    index:ids[0],
                    url:metas[ids[0]].url,
                    meta:metas[ids[0]].meta,
                    domain:domain,
                    lang:lang,
                    project:project,
                    result:result,
                    hasSubresults:ids.length-1,
                    collapse:this.collapse.bind(this),
                    unfolded:unfolded,
                    expandable:expandable
                } ))
                let subrt = []
                if ( unfolded ) {
                    for ( let i = 1 ; i < ids.length ; i++ ) {
                        const result = results[ids[i]]
                        subrt.push( React.createElement( renderer, {
                                key:project+'/'+lang+'/'+result, 
                                index:ids[i],
                                url:metas[ids[i]].url,
                                meta:metas[ids[i]].meta,
                                domain:metas[ids[i]].domain,
                                lang:lang,
                                project:project,
                                result:result,
                                subResult:true,
                                collapse:this.collapse.bind(this),
                                expandable:expandable
                        }))
                    }
                }
                rt.push(
                    <div className={'sub-results'+(subrt.length?' expanded':'')} key={'s/'+project+'/'+lang+'/'+result}>{subrt}</div>
                )
            }
        }
        return rt
    }

    renderFlat() {
        const { project, lang, results, metas, expandable } = this.state

        const renderer = api.getRenderer( project )

        let rt = []
        for ( let index = 0 ; index < metas.length ; index ++ ) {
            const result = results[index]
            rt.push( React.createElement( renderer, {
                key:project+'/'+lang+'/'+result, 
                index:index,
                url:metas[index].url,
                meta:metas[index].meta,
                domain:metas[index].domain,
                hasSubresults:0,
                group:[index],
                lang:lang,
                project:project,
                result:result,
                expandable:expandable
            }))
        }
        return rt
    }

    render() {

        return ( this.state.project && this.state.results.length )? <div className={'results-list'+(this.props.inQuery?' inquery':'')}>
            { this.state.expandable ? '' : <h1 className="project_header">{TrinetResults.projectDisplay(this.state.project)}</h1>}
            { this.state.groupDomains ? this.renderGroups() : this.renderFlat() }

            {this.gotAllMeta() ? '' :
            <div ref={this.bottomRef} className={this.state.loading ? 'next-button-loading' : 'next-button'} onClick={this.onForceExpand.bind(this)}>
                {t('More on')} <b>{TrinetResults.projectDisplay(this.state.project)}</b> ...
            </div>}
        </div> : ''

    }
} 

const projectNames = {
    'mastodonlinks':'Actualités',
    'skyblogs':'SkyBlogs',
    'secteurpublic':'Secteur Public'
}

function capitalizeFirst( str ) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

TrinetResults.projectDisplay = function(project) {
    return projectNames[project] || capitalizeFirst( project )
}

export default TrinetResults;

