import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';


function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
  
    return <div className={isFirstItemVisible?'arrow disabled':'arrow'} onClick={()=>scrollPrev()}>◀</div>
  }
  
function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return <div className={isLastItemVisible?'arrow disabled':'arrow'} onClick={()=>scrollNext()}>▶</div>
}

function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const noCapitalization = ['domain']


export default function({known,addMeta,selected}) {

    let allKnown = []
    for ( const type in known ) {
        for ( const value of known[type] ) {
            allKnown.push( [type,value[0],value[1]])
        }
    }
    allKnown.sort(
        (a,b) => {
            return (a[2]>b[2])?-1:+1
        }
    )
    let rt = []
    for ( const word of allKnown ) {
        const [type,value,_] = word
        if (( type == 'source' )&&( value.startsWith('Ajout') )) continue 
        const display = noCapitalization.includes(type) ? value : capitalize( value )
        const isSelected = ( selected.some( s => ( s[0] == type )&&( s[1] == value ) ))
        const div = <div key={type+':'+value} className={'selector'+(isSelected?' selected':'')} onClick={()=>addMeta({[type]:value})}> 
            {isSelected?'✕':''} {display}
        </div>
        if ( isSelected ) rt.unshift(div); else rt.push(div)
    }
    return (
        <ScrollMenu
            LeftArrow={LeftArrow} 
            RightArrow={RightArrow}
        >
          {rt}
        </ScrollMenu>
      );
}

/*
    return <div className="selector-row">
    {p.projects.map(
        project => <div 
            key={project.name}
            className="selector" 
            onClick={ api.setProjectName.bind(api,project.name) }
        >{project.name}</div>
    )}
    <div className="selector_right" onClick={}>▶</div>
    </div>
*/
