import React from 'react';
import ControlledInput from './controlledInput';
import logout_image from './img/logout-box-line.png';
import user_line from './img/24user-3-line.png'
import user_image from './img/24user-3-line.png'
import PaypalForm from './PaypalForm';
import wikipedia_logo from './img/Wikipedia_Logo_128.png'
import {api,t} from './trinetApi'
import TrinetResults from './TrinetResults';

export default class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            login:false,
            restricted:false,
            subscription:false,
            collapsed:true,
            recoverMessage:false,
            left:1,
            error:false,
            log_login:'',
            log_password:'',
            reg_login:'',
            reg_password:'',
            reg_password2:'',
            reg_email:'',
            moreDetails:false,
            projects:[]
        }
    }

    componentDidMount() { 
        api.common.registerAsView(this)
        api.registerAsView('projects',this)

        if ( api.firstSubscribe ) {
            this.setState({left:2})
        }
        if ( api.firstLogin ) {
            this.setState({left:1})
        }
    }

    componentWillUnmount() {
        api.common.unregisterAsView(this)
        api.unregisterAsView('projects',this)
    }

    updateProjects(projects) {
        console.log(projects)
        this.setState({projects:projects})
    }

    notifyLogin(login) {
        this.setState({login:login,error:false})
    }

    notifyRestricted(restricted) {
        this.setState({restricted:restricted})
    }

    notifySubscription(subscription) {
        this.setState({subscription:subscription})
    }

    doLogin(e) {
        this.setState({error:false})
        api.common.login( 
            this.state.log_login, 
            this.state.log_password,
            (error)=>{
                this.setState({error:error})
            }
        )
    }

    doLogout(e) {
        this.setState({error:false})
        api.common.logout()
    }

    doRegister(e) {
        if ( ! this.state.reg_login ) {
            this.setState({error:'Please choose a login'})
            return
        }
        if ( ! this.state.reg_password ) {
            this.setState({error:'Please enter a password'})
            return
        }
        if ( this.state.reg_password != this.state.reg_password_2 ) {
            this.setState({error:'Please enter twice the same password'})
            return
        }
        this.setState({error:false})
        api.common.register( 
            this.state.reg_login, 
            this.state.reg_password, 
            this.state.reg_email,
            (error)=>{
                this.setState({error:error})
            }
        )
    }

    doRecover(e) {
        api.common.recover(
            this.state.rec_login,
            this.state.rec_email,
            (res)=>{
                this.setState({'recoverMessage':true})
            },
            (res)=>{
                this.setState({'recoverMessage':true})
            }
        )
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    

    displayLogin() {
        const login = this.state.login
        const pmWikimedia = login.match(/^wp:(.*)$/)
        if ( pmWikimedia ) {
            
            return 'Wikimedia : '+this.capitalizeFirstLetter( pmWikimedia[1].replace('_',' ') )
        }
        return login
    }

    onConnectWikipedia() {
        window.location.href = 'https://meta.wikimedia.org/w/rest.php/oauth2/authorize?client_id=d5b90e1c2206b4c83eb07efe4f3d6a78&response_type=code&state='
            + api.getToken()+';'+encodeURIComponent(window.location.href)
    }

    renderCollapsed() {
        const me = this
        return <div className="login-form login-form-bandeau login-form-bandeau-clickable" onClick={this.props.onCollapse}>
            <div className="login-bandeau">
                <img src={user_image} />
            </div>
            <div className="login-bandeau">
                {t('login_or_create')}
            </div>
            <div className="login-bandeau-expand"></div>
        </div>
    }

    renderLoggedOut() {
        
        const boxClassName = this.state.left ? 'login-form-box left'+this.state.left :'login-form-box'
        return <div className={this.props.collapsed?'login-form login-form-bandeau':'login-form'}>

            <div className={boxClassName}>
                <h2>{t('forgot_password')}</h2>
                <p>{t('forgot_password_explain')}</p>
                <div className="inputs">
                    <label>{t('Enter your login')}<br/>
                    <ControlledInput type="text" container={this} property="rec_login" onSubmit={this.doRecover.bind(this)}/>
                    </label>
                    <label>{t('or your email')}<br/>
                    <ControlledInput type="text" container={this} property="rec_email" onSubmit={this.doRecover.bind(this)}/>
                    </label>
                </div>
                {this.state.recoverMessage ? <div className="message">If the account exists, a recovery email has been sent.</div>:<></>}
                <button onClick={this.doRecover.bind(this)}>{t('Recover')}</button>
                <div className="fork" onClick={()=>this.setState({left:1})}>{t('Back to login')}&nbsp;▹</div>
            </div>

            <div className={boxClassName}>
                {this.renderMoreDetails()}
                <h2>{t('Login_connect')}</h2>
                <div className="hcontainer">
                    <div className="hfill"></div>
                    <div className="hbox">
                        <div className="inputs">
                            <label>{t('Login')}<br/><ControlledInput type="text" container={this} property="log_login" onSubmit={this.doLogin.bind(this)}/></label>
                            <label>{t('Password')}<br/><ControlledInput type="password" container={this} property="log_password" onSubmit={this.doLogin.bind(this)}/></label>
                        </div>
                        {this.state.error ? <div className="error">{this.state.error}</div>:<></>}
                        <button onClick={this.doLogin.bind(this)}>{t('Login_connect')}</button>
                        <div className="fork" onClick={()=>this.setState({left:2})}>{t('Create an account')}&nbsp;▹</div>
                        <div className="fork" onClick={()=>this.setState({left:0})}>◃&nbsp;{t('forgot_password')}</div>
                    </div>
                    <div className="wikimedia_box">
                        <p className="wikimedia_title">{t('Login with Wikimedia')}</p>
                        <p><img src={wikipedia_logo} className="wikimedia_button" onClick={this.onConnectWikipedia.bind(this)}/></p>
                        <p>{t('wikimedia_explain')}</p>
                    </div>
                    <div className="hfill"></div>
                </div>
            </div>

            <div className={boxClassName}>
                {this.renderMoreDetails()}
                <h2>{t('Create an account')}</h2>
                <div className="fork" onClick={()=>this.setState({left:1})}>◃&nbsp;{t('Already have an account ?')}</div>
                <div className="inputs">
                    <label>{t('pick_login')}<br/>
                    <ControlledInput type="text" container={this} property="reg_login" onSubmit={this.doRegister.bind(this)}/>
                    </label>
                    <label>{t('pick_password')}<br/>
                    <ControlledInput type="password" container={this} property="reg_password" onSubmit={this.doRegister.bind(this)}/>
                    </label>
                    <label>{t('pick_password_again')}<br/>
                    <ControlledInput type="password" container={this} property="reg_password_2" onSubmit={this.doRegister.bind(this)}/>
                    </label>
                    <label>{t('your_email_optional')}<br/>
                    <ControlledInput type="text" container={this} property="reg_email" onSubmit={this.doRegister.bind(this)}/>
                    </label>
                </div>
                {this.state.error ? <div className="error">{this.state.error}</div>:<></>}
                <button onClick={this.doRegister.bind(this)}>{t('Register')}</button>
            </div>
        </div>
    }

    renderMoreDetails() {

        let totalCount = 0
        return <>
            <a className={this.state.moreDetails?'unfold':''} onClick={()=>{this.setState(prev=>({ moreDetails:!prev.moreDetails }) )}}>{t('More details')}</a>
            <div className={this.state.moreDetails?'averageunfolded':'totalfolded'}><table className="details_wikiwix">
                <thead>
                    <tr><th>{t('Project')}</th><th>{t('Indexed documents')}</th></tr>
                </thead>
                <tbody>
                { this.state.projects.map( project => { 
                    if ( !isNaN( project.allCount ) ) totalCount += project.allCount
                    return <tr key={project.name}>
                    <td>{TrinetResults.projectDisplay(project.name)}</td><td>{api.displayNumber(project.allCount)}</td>
                </tr> } )}
                <tr><th>Total</th><th>{api.displayNumber(totalCount)}</th></tr>
            </tbody></table></div>
        </>
    }

    renderLoggedIn() {

        const subscription = this.state.subscription
        const ds = ( subscription && subscription.valid_until )? 1000*subscription.valid_until : false;
        let subscriptionBox = <></>
        let subscriptionLine = <></>
        if ( !( this.state.restricted || subscription )) {
            /* Unlimited without subscription : Wikipedia users ! */
            subscriptionBox = <>
                <p>{t('have_unlimited')}</p>
            </>
            subscriptionLine = <><span className="sep">{this.props.collapsed ? '▸' : '▾'}</span>Unlimited access</>
        } else {
            if ( subscription ) {
                if ( subscription.status == 'ACTIVE' ) {
                    subscriptionBox = <>
                    <p>{t('have_subscription')}</p>
                    <button className="btn-search" onClick={(e)=>{e.stopPropagation() ; api.common.cancelSubscription( subscription.id )}}>{t('Cancel my subscription')}</button></>
                } else {
                    if ( subscription.valid && ds ) {
                        subscriptionBox = <>{t('still_have_unlimited',t(ds))}</>
                    }
                    subscriptionBox = <>{subscriptionBox}<br/><PaypalForm/></>
                }
                subscriptionBox =  <><h2>{subscription.status ? t('Your unlimited plan is')+' '+t(subscription.status) : t('No subscription') }</h2> {subscriptionBox}</>
                subscriptionLine = <><span className="sep">{this.props.collapsed ? '▸' : '▾'}</span>{subscription.status ? t(subscription.status).toLowerCase() : t('No subscription') }</>
            } else {
                subscriptionBox = <PaypalForm api={api}/>
                subscriptionLine = <><span className="sep">{this.props.collapsed ? '▸' : '▾'}</span>{t('No subscription')}</>
            }
        }

        const logoutBox = <div className="user_box">
            <div className="logout_box">
                <img src={user_line}/> {this.state.login}
            </div>
            <div className="logout_box" onClick={this.doLogout.bind(this)} title={t('Logout')}>
                <img src={logout_image}/>
            </div>
        </div>

        return this.state.restricted ? <div className={this.props.collapsed?'login-form login-form-bandeau':'login-form'}>
            <div className="login-form-box">
                {logoutBox}
                {this.renderMoreDetails()}
                <h2>{t('Subscribe to Wikiwix Archive')}</h2>
                <p>{t('for_x_get_access',api.price)}</p>
                <PaypalForm api={api}/>
            </div>
        </div> : <div className={this.props.collapsed?'login-form login-form-bandeau':'login-form'}>
            <div className="login-form-box">
                {logoutBox}
                {this.renderMoreDetails()}

                {subscriptionBox}
            </div>

        </div>

    }

    render() {
        return this.state.login ? this.renderLoggedIn() : this.renderLoggedOut()
    }
}
