import React from 'react';

class ControlledInput extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const container = this.props.container
        const property = this.props.property
        const onSubmit = this.props.onSubmit

        return <><input 
                id = {this.props.id}
                className = {this.props.className}
                type={this.props.type} 
                value={container.state[property]} 
                name={property} 
                placeholder={this.props.placeholder}
                onChange={event=>{
                    container.setState({[property]:event.target.value})
                }}
                onKeyUp={e=>{
                    if (e.key === 'Enter') {
                        if ( onSubmit ) onSubmit(e)
                    }
            }}/>
            </>
    }
}

export default ControlledInput;