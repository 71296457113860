import {t} from '../trinetApi'

export default function ResultBox(props) {

    const {hasSubresults,domain,collapse,unfolded,children} = props

    return <div className="result-item">
        {children}
        {hasSubresults ? <a className="result-item-others" onClick={e=>collapse(domain)}>{unfolded?'▾':'▸'}&nbsp; {hasSubresults} {t((hasSubresults>1)?'more results in':'more result in')} <b>{domain}</b></a> : ''}
    </div>
}