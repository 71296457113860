import $ from 'jquery';
import l18n from './l18n';

export default class CommonApi {

    constructor(url) {
        this.baseUrl = url+'service/' 
        this.views = []
        this.user = false
        this.restricted = false
        this.subscription = false
        this.wikimediaUser = false
        this.returnAfter = false
        this.l18n = new l18n()
        this.t = this.l18n.getter()
    }

    registerAsView( view ) {
        this.views.push(view)
    }

    unregisterAsView( view ) {
        this.views = this.views.filter( v => view!=v )
    }

    receive(res) {
        if ( res.hasOwnProperty('user' ) ) {
            if ( res.hasOwnProperty('token') ) {
                document.cookie = "token="+res.token+";Max-Age=2592000;path=/";
            }
            document.cookie = "login="+res.user+";Max-Age=2592000;path=/";

            if ( this.user != res.user ) {
                this.user = res.user
//                this.wikimediaUser = !! this.user.match(/^wp:(.*)$/)
                this.views.forEach( view => view.notifyLogin(this.user) )
/*                if ( this.wikimediaUser && this.restricted ) {
                    this.restricted = false
                    this.views.forEach( view => view.notifyRestricted ? view.notifyRestricted(this.restricted) : false )
                }*/
            }
        }
        if ( res.hasOwnProperty('restricted' ) ) {
//            if ( this.wikimediaUser ) res.restricted = false
            if ( this.restricted != res.restricted ) {
                this.restricted = res.restricted
                if ( this.returnAfter && ! this.restricted ) document.location = this.returnAfter
                this.views.forEach( view => view.notifyRestricted ? view.notifyRestricted(this.restricted) : false )
            }
        }
        if ( res.hasOwnProperty('subscription' ) ) {
            if ( this.subscription != res.subscription ) {
                this.subscription = res.subscription
                this.views.forEach( view => view.notifySubscription ? view.notifySubscription(this.subscription) : false )
            }
        }

    }

    login( login, password, onError ) {
        this.post({
            action:'connect',
            login:login,
            password:password
        }, ()=>{}, onError )
    }

    logout() {
        if ( this.user ) {
            this.user = false
            document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
            document.cookie = "login=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
            this.views.forEach( view => view.notifyLogin(false) )
        }
    }

    register( login, password, email, onError ) {
        this.post({
            action:'register',
            login:login,
            password:password,
            email:email
        }, ()=>{}, onError )
    }

    recover( login, email, onSuccess, onError ) {
        this.post({
            action:'recover',
            login:login,
            email:email,
            url:'https://archive.wikiwix.com/cache/'
        }, onSuccess, onError )
    }

    getToken() {
        var match = document.cookie.match(/(?:^|;)\s*token\s*=\s*([0-9a-f]+)/)
        var ret = match ? match[1] : false
        return ret
    }

    post( what, onSuccess, onError ) {
        const me = this
        what.token = this.getToken()
        $.ajax({
            url: this.baseUrl+'api.php',
            type:'POST',
            data:what,
            dataType: 'json',
            success: function(res){
                if ( res.error ) {
                    if ( onError ) onError(res.error)
                    else me.showError(res.error)
                } else {
                    if ( onSuccess ) onSuccess(res)
                    me.receive(res)
                }
            },
            error: function(e) {
                me.showError('Internal error 1')
            }
        });
    }

    showError(error) {
        console.log(error)
    }


    createSubscription(plan) {
        return fetch(this.baseUrl+"paypal.php", {
            method: "post",
            body:new URLSearchParams({
                action:'subscriptions',
                token:this.getToken(),
                plan:plan
            })
        })
        .then((response) => response.json())
        .then((order) => order.id);
    }

      // Finalize the transaction after payer approval
    onApprove(data, actions) {
        return fetch(this.baseUrl+"paypal.php", {
          method: "post",
          body:new URLSearchParams({
            action:'capture_subscription',
            subscriptionID:data.subscriptionID
          })
        })
        .then((response) => response.json())
        .then((data) => {
            this.updateSubscription( data.user, data.subscription )
        });
    }

    updateSubscription( user, subscription ) {

        console.log(user,subscription)
        this.receive({
            user:user,
            subscription:subscription,
            restricted:!subscription.valid
        })
    }

    cancelSubscription( subscriptionID ) {
        return fetch(this.baseUrl+'paypal.php', {
            method: "post",
            body:new URLSearchParams({
              action:'cancel_subscription',
              subscriptionID:subscriptionID
            })
        }).then((response) => response.json())
        .then((data) => {
            this.updateSubscription( data.user, data.subscription )
        });

    }
}

